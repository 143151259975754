import React, { Fragment } from "react"
import { Link } from "gatsby"
import Img1 from '@/images/new/midia/img1.png'
import Img2 from '@/images/new/midia/img2.png'
import Img3 from '@/images/new/midia/img3.png'
import Img4 from '@/images/new/midia/img4.png'
import Img5 from '@/images/new/midia/img5.png'
import Img6 from '@/images/new/midia/img6.png'
import Img7 from '@/images/new/midia/img7.png'
import Img8 from '@/images/new/midia/img8.png'
import Img9 from '@/images/new/midia/img9.png'

const EdicoesAnteriores = ({ }) => {
  return (
    <Fragment>
      <section className="pageBanner-Midia">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="banner_content">
                <h2>Na Mídia<br />Clippings e Matérias</h2>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="itens-midia">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <img className="img-size MB-32" src={Img1} alt='/' />
            </div>
						<div className="col-lg-6">
              <h2 className="title-midia" style={{ marginBottom: 24 }}>Olimpíadas de conhecimento: uma oportunidade para melhorar a educação de forma rápida, barata e eficaz</h2>
							<p className="paragraph-midia" style={{ marginBottom: 32 }}>A educação no Brasil está patinando. Os dados do PISA, prova internacional que mede o desempenho dos alunos em matemática, ciências e leitura, foram divulgados . . .</p>
							<Link className="blue_btn" to='' style={{ padding: '25px 70px' }}>
                <span>Saiba mais</span>
              </Link>
						</div>
          </div>
					<div className="line-border-2" style={{ margin: '74px 0' }} />
					<div className="row">
            <div className="col-lg-6">
              <img className="img-size MB-32" src={Img2} alt='/' />
            </div>
						<div className="col-lg-6">
              <h2 className="title-midia" style={{ marginBottom: 24 }}>Estudantes de Mossoró desenvolvem canudo biodegradável e comestível e ganham medalha de ouro nas Olimpíadas do Futuro</h2>
							<p className="paragraph-midia" style={{ marginBottom: 32 }}>Cinco estudantes de Mossoró, no Oeste potiguar, foram os vencedores das Olimpíadas do Futuro, que foca em iniciativas ligadas a problemas enfrentados no século XXI . . .</p>
							<Link className="blue_btn" to='' style={{ padding: '25px 70px' }}>
                <span>Saiba mais</span>
              </Link>
						</div>
          </div>
					<div className="line-border-2" style={{ margin: '74px 0' }} />
					<div className="row">
            <div className="col-lg-6">
              <img className="img-size MB-32" src={Img3} alt='/' />
            </div>
						<div className="col-lg-6">
              <h2 className="title-midia" style={{ marginBottom: 24 }}>Olimpíada do Futuro</h2>
							<p className="paragraph-midia" style={{ marginBottom: 32 }}>Trabalho desenvolvido por estudantes da escola SESI Mossoró e ganhador da Sapientia – Olimpíada do Futuro 2019 foi destaque na imprensa nacional, durante o final de . . .</p>
							<Link className="blue_btn" to='' style={{ padding: '25px 70px' }}>
                <span>Saiba mais</span>
              </Link>
						</div>
          </div>
					<div className="line-border-2" style={{ margin: '74px 0' }} />
					<div className="row">
            <div className="col-lg-6">
              <img className="img-size MB-32" src={Img4} alt='/' />
            </div>
						<div className="col-lg-6">
              <h2 className="title-midia" style={{ marginBottom: 24 }}>Mossoroenses ganham 1º e 2º lugares da Olimpíada do Futuro</h2>
							<p className="paragraph-midia" style={{ marginBottom: 32 }}>Alunos da Escola Sesi (Serviço Social da Indústria) de Mossoró foram destaque numa reportagem do programa Fantástico da Rede Globo de Televisão, nesse domingo . . .</p>
							<Link className="blue_btn" to='' style={{ padding: '25px 70px' }}>
                <span>Saiba mais</span>
              </Link>
						</div>
          </div>
					<div className="line-border-2" style={{ margin: '74px 0' }} />
					<div className="row">
            <div className="col-lg-6">
              <img className="img-size MB-32" src={Img5} alt='/' />
            </div>
						<div className="col-lg-6">
              <h2 className="title-midia" style={{ marginBottom: 24 }}>Inspirados por Yuval Harari, estudantes criam projeto para combater plástico</h2>
							<p className="paragraph-midia" style={{ marginBottom: 32 }}>Ele fala para plateias sempre lotadas no mundo todo. Estudantes, celebridades, políticos, empresários. Seus livros fazem a cabeça de figuras como Barack Obama, Bill . . .</p>
							<Link className="blue_btn" to='' style={{ padding: '25px 70px' }}>
                <span>Saiba mais</span>
              </Link>
						</div>
          </div>
					<div className="line-border-2" style={{ margin: '74px 0' }} />
					<div className="row">
            <div className="col-lg-6">
              <img className="img-size MB-32" src={Img6} alt='/' />
            </div>
						<div className="col-lg-6">
              <h2 className="title-midia" style={{ marginBottom: 24 }}>Estudantes baianos criam projeto para minimizar impacto do lixo urbano</h2>
							<p className="paragraph-midia" style={{ marginBottom: 32 }}>Em maio deste ano, as tradicionais chuvas do mês fizeram um estrago maior que o esperado no município vizinho de Lauro de Freitas (Região Metropolitana de Salvador). O drama . . .</p>
							<Link className="blue_btn" to='' style={{ padding: '25px 70px' }}>
                <span>Saiba mais</span>
              </Link>
						</div>
          </div>
					<div className="line-border-2" style={{ margin: '74px 0' }} />
					<div className="row">
            <div className="col-lg-6">
              <img className="img-size MB-32" src={Img7} alt='/' />
            </div>
						<div className="col-lg-6">
              <h2 className="title-midia" style={{ marginBottom: 24 }}>Estudantes cearenses são medalha de ouro na primeira edição da olimpíada Sapientia</h2>
							<p className="paragraph-midia" style={{ marginBottom: 32 }}>Os cearenses Yan Benevinuto e Mateus Dumont foram premiados com medalha de ouro na primeira edição do Sapientia - Olimpíada do Futuro. Os participantes . . .</p>
							<Link className="blue_btn" to='' style={{ padding: '25px 70px' }}>
                <span>Saiba mais</span>
              </Link>
						</div>
          </div>
					<div className="line-border-2" style={{ margin: '74px 0' }} />
					<div className="row">
            <div className="col-lg-6">
              <img className="img-size MB-32" src={Img8} alt='/' />
            </div>
						<div className="col-lg-6">
              <h2 className="title-midia" style={{ marginBottom: 24 }}>Olimpíada do Futuro está com inscrições abertas</h2>
							<p className="paragraph-midia" style={{ marginBottom: 32 }}>A editora Companhia das Letras e o Instituto Vertere promovem uma competição de conhecimento transdisciplinar envolvendo estudantes, professores e escolas: Sapientia . . .</p>
							<Link className="blue_btn" to='' style={{ padding: '25px 70px' }}>
                <span>Saiba mais</span>
              </Link>
						</div>
          </div>
					<div className="line-border-2" style={{ margin: '74px 0' }} />
					<div className="row">
            <div className="col-lg-6">
              <img className="img-size MB-32" src={Img9} alt='/' />
            </div>
						<div className="col-lg-6">
              <h2 className="title-midia" style={{ marginBottom: 24 }}>Primeira edição de Olimpíada testa conhecimentos de diferentes assuntos</h2>
							<p className="paragraph-midia" style={{ marginBottom: 32 }}>ovens do Brasil todo, assim como professores, pais e interessados, podem participar da 1ª edição da Sapientia – Olimpíada do Futuro, que é uma iniciativa da . . .</p>
							<Link className="blue_btn" to='' style={{ padding: '25px 70px' }}>
                <span>Saiba mais</span>
              </Link>
						</div>
          </div>
					<div className="line-border-2" style={{ margin: '74px 0' }} />
        </div>
      </section>
    </Fragment>
  );
};

export default EdicoesAnteriores;
